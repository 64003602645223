import { Utils } from 'Shared/utils';
import { MediaItem } from './item';
import { MediaItemImage, MediaItemImageDisplay } from './item_image';
import { setValidChoiceClass } from './item_display';

const VideoEncodings = App.videoEncodings;

/**
 * Class MediaItemVideo
 *
 * Represents a MediaItem of type video.
 */
const MediaItemVideo = new Class({
   Extends: MediaItemImage,

   /**
    * Returns a list of menu functions that should be allowed
    * for this media item in it's current state.
    */
   _getMenuOptions: function () {
      return {};
   },

   /**
    * Creates an appropriate representation for the given MediaTarget and
    * passes it as the only argument to the provided callback when it's
    * ready.
    */
   createRepresentationFor: function (mediaTarget, callback) {
      let size = mediaTarget.options.displaySize;
      let self = this;
      self.whenReady(() => {
         let thumb = self.createDisplay(size, false);
         thumb.whenReady(() => {
            callback(thumb);
         });
      });
   },

   /**
    * Instantiates a MediaItemImageDisplay for this video
    * whose element will automatically update to reflect any state
    * changes in this item.
    */
   createDisplay: function (size, showFilename) {
      let thumb;
      size = this.getLargestImageSize(size) || 'standard';

      thumb =
         size == 'thumbnail'
            ? new MediaItemImageDisplay(this, {
                 size: size,
                 useBackground: true,
                 showFilename: showFilename,
                 classOverride: 'mediaVideo',
              })
            : new MediaItemVideoDisplay(this, {
                 size: size,
                 showFilename: showFilename,
              });
      return thumb;
   },

   /**
    * Returns the underlying objectid that this mediaItem represents.
    */
   getID: function () {
      return this.data.objectid();
   },

   getType: function () {
      return this.data.type();
   },

   getFilter: function () {
      return 'onlyObjects';
   },

   toWikiText: function () {
      return '[video|' + this.data.videoid() + ']';
   },

   /**
    * Returns the media type and underlying imageid that this mediaItem
    * represents.
    */
   getGlobalID: function () {
      return 'video:' + this.getID();
   },
});

MediaItem.registerType('GuideVideoObject', MediaItemVideo);

/**
 * Represents a DOM element that displays a MediaItemVideo
 *
 * options: {
 * }
 *
 * Fires Events:
 *    select(mediaItem): when the DOM element is clicked
 */
function MediaItemVideoDisplay(mediaItem, options) {
   options = options || {};
   let data = mediaItem.data;
   let self = this;
   self.size = options.size;
   this.mediaItem = mediaItem;
   this._elementPromise = new Future();
   this._small = !MediaItem.isSizeAsBigAs(options.size, 'medium');

   // The container that holds everything.
   let container = (this.container = new Element('div.mediaItem.mediaObject'));

   // Add a menu control
   container.adopt(MediaItem.createMenuControl(mediaItem));

   if (this._small) {
      // Listen for click and fire "select" (the library listens for this)
      container.addEvent('click', this.fireEvent.pass(['select', mediaItem], this));
   }

   // Create container for file name and type
   if (options.showFilename) {
      let filecontainer = new Element('span.file-container');
      let filetypeContainer = new Element('div.filetypeContainer');
      this.filename = new Element('p.filename');
      this.filetype = new Element('div.filetype');
      let icon = new Element('i.fileicon.fa.fa-video-camera');

      this._updateFilename();

      filecontainer.grab(this.filename);
      container.grab(filetypeContainer);
      container.grab(filecontainer);
      container.grab(this.filetype);
      container.grab(icon);
   }

   // Store a reference to this instance with the DOM element, so that other
   // code can always get back to the class from the DOM.
   this.container.store('mediaItem', mediaItem);

   mediaItem.addEvents({
      dataChanged: this._updateDisplay.bind(this),
      loadingChanged: this._updateLoading.bind(this),
      deleted: this.dispose.bind(this),
   });

   if (mediaItem.isReady()) {
      self._updateDisplay();
   } else {
      self._updateLoading(true);
   }
}

// Inherit from MediaItemImageDisplay
Object.append(MediaItemVideoDisplay.prototype, MediaItemImageDisplay.prototype);

Object.append(MediaItemVideoDisplay.prototype, {
   /**
    * Updates the DOM element to reflect the current state of the
    * media item.
    */
   _updateDisplay: function () {
      setValidChoiceClass(this.mediaItem.isValidChoice(), this.container);

      if (this._small) {
         this._updateImageDisplay();
         return;
      }

      this._updateVideoDisplay();
   },

   _updateVideoDisplay: function () {
      let mediaItem = this.mediaItem;
      let self = this;
      let video = Utils.createElements({
         tag: 'video',
         controls: true,
         autoplay: false,
         preload: 'auto',
         children: this._createSourceElements(),
      });

      self._elementPromise = new Future();
   },

   /**
    * Create <source .. /> elements for use inside <video> elements.
    */
   _createSourceElements: function () {
      let videoUrls = this.mediaItem.data.video_urls();
      let toElement = Utils.createElements;
      let elements = [];

      Object.each(videoUrls, (value, name) => {
         let encodingInfo = VideoEncodings[name.toUpperCase()];
         elements.push(
            toElement({
               tag: 'source',
               src: value,
               codecs: encodingInfo.codecs,
               type: encodingInfo.mime,
            })
         );
      });

      return elements;
   },

   _updateImageDisplay: function () {
      let mediaItem = this.mediaItem;
      let self = this;
      let imgUrl = mediaItem.getSrc(self.size);

      // Create a new promise if this isn't the first time
      if (!self._elementPromise || self._displayElement) {
         self._elementPromise = new Future();
      }

      let img = self._displayElement;
      if (!img) {
         img = self._displayElement = new Element('img.' + self.size);
         self.container.grab(img);
      }
      img.set('src', imgUrl);

      // Give the UI thread a chance to catch up.
      (function () {
         self._elementPromise.resolve(imgUrl);
      }).delay(10);
   },

   _updateLoading: function (loading) {},
});
